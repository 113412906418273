import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, TextField, Link, Modal } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import useUser from "../hooks/useUser";
import ShowableInput from "../components/ShowableInput/ShowableInput";
import LicenseAgreement from "../components/LicenseAgreement/LicenseAgreement";

const RegistrationView = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    name: "",
    organization: "",
    email: "",
    password: "",
    repeatedPassword: "",
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { registration } = useUser();

  const handleChange = prop => event => {
    if (
      (prop === "password" || prop === "repeatedPassword") &&
      (event.target.value.match(/[а-я]+/) || event.target.value.match(/[А-Я]+/))
    ) {
      setError("Пароль не может содержать кирилицу");
    } else {
      setError("");
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const handleOpenModal = e => {
    e.preventDefault();

    const regexPassword =
      /^(?=.*[-\#\$\.\%\&\@\!\+\=\\*])(?=.*[a-zA-Z])(?=.*\d).{8,}$/;
    const regexEmail =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (values.name === "") {
      setError(`Поле "Имя" не заполнено`);
    } else if (values.organization === "") {
      setError(`Поле "Организация" не заполнено`);
    } else if (values.email === "" || !values.email.match(regexEmail)) {
      setError(`Поле "Email" может быть пустым или не корректен`);
    } else if (
      values.password === "" ||
      !values.password.match(regexPassword)
    ) {
      setError(
        `Поле "Пароль" может быть пустым, пароль должен содержать: не менее 8 символов, 1 заглавную букву, цифры и специальный символ`
      );
    } else if (
      values.repeatedPassword === "" ||
      !values.repeatedPassword.match(regexPassword)
    ) {
      setError(
        `Поле "Повторите пароль" может быть пустым, пароль должен содержать: не менее 8 символов, 1 заглавную букву, цифры и специальный символ`
      );
    } else {
      setIsOpen(true);
      setError("");
    }
  };

  const handleCloseModal = e => {
    e.preventDefault();

    setIsOpen(false);
    setIsChecked(false);
  };

  const handleChangeIsChecked = e => {
    e.preventDefault();

    setIsChecked(e.target.checked);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setError("");
    setIsLoading(true);

    await registration(values)
      .then(res => {
        setIsLoading(false);
        setTimeout(() => {
          navigate("/authorization/login");
        }, 1000);
      })
      .catch(res => {
        const errorsMessages = {
          400: "Проверте поля, пароль должен содержать: не менее 8 символов, 1 заглавную букву, цифры и специальный символ",
          409: "Email занят",
          500: "Ошибка сервера",
        };

        setError(errorsMessages[res.response.status]);
        setIsLoading(false);
        setIsOpen(false);
        setIsChecked(false);
      });
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#0D141E",
      }}
    >
      <Box
        sx={{
          width: {
            lg: "50vw",
            md: "60vh",
            sm: "80vw",
            xs: "100vw",
          },
          height: {
            sm: "75vh",
            xs: "80vh",
          },
          p: {
            sm: "40px",
            xs: "30px",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          background:
            "linear-gradient(269.39deg, rgba(25, 44, 61, 0.48) 3.53%, rgba(14, 44, 71, 0.48) 101.76%)",
        }}
      >
        <Typography
          variant="h4"
          sx={theme => ({ mb: "1rem", color: theme.palette.text.primary })}
        >
          Регистрация
        </Typography>

        <Box
          sx={{
            width: "100%",
            height: {
              sm: "80%",
              xs: "70%",
            },
          }}
        >
          <form
            onSubmit={handleOpenModal}
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Box
              sx={{
                width: "100%",
                padding: 0,
                marginBottom: {
                  xs: "20px",
                },
              }}
            >
              <TextField
                value={values.name}
                label="Имя"
                variant="outlined"
                placeholder={`Введите имя`}
                sx={{
                  width: "100%",
                  backgroundColor: "#141d2b",
                }}
                InputProps={{
                  sx: {
                    fieldset: {
                      borderColor: "rgb(149 149 149 / 23%)",
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: "rgb(149 149 149)",
                  },
                }}
                onChange={handleChange("name")}
              />
            </Box>

            <Box
              sx={{
                width: "100%",
                padding: 0,
                marginBottom: {
                  xs: "20px",
                },
              }}
            >
              <TextField
                value={values.organization}
                label="Организация"
                variant="outlined"
                placeholder={`Введите название организации`}
                sx={{
                  width: "100%",
                  backgroundColor: "#141d2b",
                }}
                InputProps={{
                  sx: {
                    fieldset: {
                      borderColor: "rgb(149 149 149 / 23%)",
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: "rgb(149 149 149)",
                  },
                }}
                onChange={handleChange("organization")}
              />
            </Box>

            <Box
              sx={{
                width: "100%",
                padding: 0,
                marginBottom: {
                  xs: "20px",
                },
              }}
            >
              <TextField
                value={values.email}
                label="Email"
                variant="outlined"
                placeholder={`Введите Email`}
                sx={{
                  width: "100%",
                  backgroundColor: "#141d2b",
                }}
                InputProps={{
                  sx: {
                    fieldset: {
                      borderColor: "rgb(149 149 149 / 23%)",
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: "rgb(149 149 149)",
                  },
                }}
                onChange={handleChange("email")}
              />
            </Box>

            <Box
              sx={{
                width: "100%",
                padding: 0,
                marginBottom: {
                  xs: "20px",
                },
              }}
            >
              <ShowableInput
                inputLabel={"Пароль"}
                id={"registration-password"}
                value={values.password}
                handleChange={handleChange("password")}
              />
            </Box>

            <Box
              sx={{
                width: "100%",
                padding: 0,
                marginBottom: {
                  xs: "20px",
                },
              }}
            >
              <ShowableInput
                inputLabel={"Повторите пароль"}
                id={"registration-repeatedPassword"}
                value={values.repeatedPassword}
                handleChange={handleChange("repeatedPassword")}
              />
            </Box>

            <Box
              sx={{
                height: "10%",
              }}
            >
              {error && (
                <Typography
                  variant="h7"
                  sx={{
                    mb: "1rem",
                    color: "red",
                  }}
                >
                  {error}
                </Typography>
              )}
            </Box>

            <LoadingButton
              loading={isLoading}
              variant="contained"
              type="submit"
              sx={{
                width: "60%",
                maxHeight: "10%",
                minHeight: "10%",
                backgroundColor: "#42a5f5",
              }}
              color="primary"
            >
              Зарегистрироваться
            </LoadingButton>
          </form>
        </Box>

        <Box
          sx={{
            width: "100%",
            height: "10%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Link href="/authorization/login">Уже зарегистрирован</Link>
        </Box>
      </Box>

      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <LicenseAgreement
            isLoading={isLoading}
            checked={isChecked}
            handleChangeChecked={handleChangeIsChecked}
            handleCloseModal={handleCloseModal}
            handleSubmit={handleSubmit}
            error={error}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default RegistrationView;
