import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import StyledTableCell from "../../components/TableCell/StyledTableCell";
import Status from "../../components/Status/Status";

const ProjectsTable = ({
  HeadRows,
  BodyRows,
  onClickProject,
  orderDirection,
  setOrderDirection,
  setDirection,
  valueToOrderBy,
  setValueToOrderBy,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}) => {
  const handleSortBy = item => {
    const isAscending = valueToOrderBy === item && orderDirection === "asc";
    setValueToOrderBy(item);
    setOrderDirection(isAscending ? "desc" : "asc");
    setDirection(isAscending ? "-1" : "1");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value), 10);
    setPage(1);
  };

  return (
    <>
      <TableContainer
        sx={{
          height: "50%",
          maxHeight: "90%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Table stickyHeader sx={{ width: "80%", height: "100%" }}>
          <TableHead>
            <TableRow>
              {HeadRows.map((item, i) => (
                <StyledTableCell key={item.value}>
                  <TableSortLabel
                    active={valueToOrderBy === item.value}
                    direction={
                      valueToOrderBy === item.value ? orderDirection : "asc"
                    }
                    onClick={() => handleSortBy(item.value)}
                  >
                    {item.name}
                  </TableSortLabel>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {BodyRows?.results?.map((row, index) => (
              <TableRow key={row.name + index}>
                <StyledTableCell
                  onClick={
                    onClickProject ? () => onClickProject(row._id) : null
                  }
                  id={row._id}
                  sx={{ maxWidth: 250 }}
                >
                  <p
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {row.name}
                  </p>
                </StyledTableCell>
                <StyledTableCell>{row.updatedAt.split("T")[0]}</StyledTableCell>
                <StyledTableCell>{row.organization}</StyledTableCell>
                <StyledTableCell>
                  {row.status === "success" ? (
                    <>
                      <Status status={"Обработан"} />
                      {"Обработан"}
                    </>
                  ) : row.status === "progress" ? (
                    <>
                      <Status status={"Прогресс"} />
                      {"Прогресс"}
                    </>
                  ) : !row.status ? (
                    <>
                      <Status status={"Не расчитан"} />
                      {"Не расчитан"}
                    </>
                  ) : (
                    <>
                      <Status status={"Ошибка"} />
                      {"Ошибка"}
                    </>
                  )}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={BodyRows.resultCount ? BodyRows.resultCount : 0}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      ></TablePagination>
    </>
  );
};

export default ProjectsTable;
