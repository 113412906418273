import { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  IconButton,
  Typography,
  Input,
  Button,
  Modal,
} from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Loader } from "../components/Loader/Loader";
import useUser from "../hooks/useUser";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PasswordIcon from "@mui/icons-material/Password";
import ShowableInput from "../components/ShowableInput/ShowableInput";

const ProfileView = ({ user, setUserInfo, activeUserKey }) => {
  const InputAvatar = useRef(null);
  const [profileAvatar, setProfileAvatar] = useState(null);
  const [isChangeData, setIsChangeData] = useState(true);
  const [isChangePassword, setIsChangePassword] = useState(true);
  const [isChangeEmail, setIsChangeEmail] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [changedUserInfo, setChangedUserInfo] = useState(null);
  const [passwordInfo, setPasswordInfo] = useState({
    password: "",
    newPassword: "",
    newPasswordRepeated: "",
  });

  const { updateProfile, sendChangeEmail, uploadAvatar, changePassword } =
    useUser();

  useEffect(() => {
    if (user) {
      if (user.avatarUrl) {
        setProfileAvatar(`http://localhost:3002/file/${user.avatarUrl}`);
      }
      setChangedUserInfo(user);
    }
  }, [user]);

  const handleIsChangeEmail = () => {
    setIsChangeData(true);
    setIsChangePassword(true);
    setIsChangeEmail(false);
  };

  const handleIsChangeData = () => {
    setIsChangeEmail(true);
    setIsChangePassword(true);
    setIsChangeData(!isChangeData);
  };

  const handleIsChangePassword = () => {
    setIsChangeData(true);
    setIsChangeEmail(true);
    setIsChangePassword(!isChangePassword);
  };

  const handleSubmitVerifyEmail = async () => {
    if (user.email !== changedUserInfo.email) {
      const newData = {
        _id: changedUserInfo._id,
        newEmail: changedUserInfo.email,
      };

      await sendChangeEmail(newData)
        .then(res => {
          setError("Подтверждение смены отправлено на указанную почту");
          setIsError(true);
          setIsChangeEmail(true);
        })
        .catch(res => {
          const errorsMessages = {
            404: "Данный email занят другим пользователем",
          };

          setError(errorsMessages[res.response.status]);
          setIsError(true);
        })
        .finally(() => {
          setIsChangeEmail(true);
        });
    } else {
      setError("Нет изменения в данных");
      setIsError(true);
      setIsChangeEmail(true);
    }
  };

  const handleChangeData = async () => {
    if (
      user !== changedUserInfo &&
      changedUserInfo.name !== "" &&
      changedUserInfo.organization !== ""
    ) {
      await updateProfile(changedUserInfo)
        .then(res => {
          setUserInfo(changedUserInfo);
        })
        .catch(res => {})
        .finally(() => {
          setIsChangeData(true);
        });
    } else {
      setError("Нет изменения в данных или не все поля заполнены");
      setIsError(true);
      setIsChangeData(true);
    }
  };

  const handleSubmitChangePassword = async () => {
    if (
      passwordInfo.password &&
      passwordInfo.newPassword &&
      passwordInfo.password !== passwordInfo.newPassword
    ) {
      if (passwordInfo.newPassword === passwordInfo.newPasswordRepeated) {
        await changePassword({
          password: passwordInfo.password,
          newPassword: passwordInfo.newPassword,
        })
          .then(res => {
            setError("Пароль успешно изменен");
            setIsError(true);
            setIsChangePassword(true);
            setPasswordInfo({
              password: "",
              newPassword: "",
              newPasswordRepeated: "",
            });
          })
          .catch(res => {
            const errorsMessages = {
              400: "Пароль не верен",
              401: "Неверные данные для авторизации",
              404: "Пользователь не найден",
            };

            setError(errorsMessages[res?.response?.status]);
            setIsError(true);
          });
      } else {
        setError("Значение полей нового пароля не совпадают");
        setIsError(true);
      }
    } else {
      setError("Старый и новый пароли совпадают или поля не заполнены");
      setIsError(true);
    }
  };

  const handleCloseIsError = () => {
    setError("");
    setIsError(false);
  };

  const handleChange = prop => event => {
    setError("");
    const item = prop.toString().toLowerCase();
    setChangedUserInfo({ ...changedUserInfo, [item]: event.target.value });
  };

  const handleChangePasswordFields = prop => event => {
    setError("");
    setPasswordInfo({ ...passwordInfo, [prop]: event.target.value });
  };

  const handleChangeAvatar = () => {
    InputAvatar.current.click(), "fileUpload";
  };

  const handleChangeAvatarInput = async e => {
    const avatar = e.target.files[0];

    await uploadAvatar(avatar)
      .then(res => {
        setProfileAvatar(`http://localhost:3002/file/${res.data}`);
      })
      .catch(res => {
        const errorsMessages = {
          401: "Неверные данные для авторизации",
          404: "Пользователь не найден",
        };

        setError(errorsMessages[res?.response?.status]);
        setIsError(true);
      });
  };

  const stringAvatar = name => {
    if (name.split(" ")[1]) {
      return {
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
      };
    } else {
      return {
        children: `${name.split(" ")[0][0]}`,
      };
    }
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: {
            sm: "70%",
            xs: "90%",
          },
          mt: {
            xs: "54px",
            md: "64px",
          },
          ml: {
            xs: "0px",
            md: "64px",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          className="user-info"
          sx={{
            width: "100%",
            height: "80%",
            p: "1rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: `linear-gradient(269.39deg, rgba(25, 44, 61, 0.48) 3.53%, rgba(14, 44, 71, 0.48) 101.76%)`,
          }}
        >
          {changedUserInfo ? (
            <>
              <Box
                sx={{
                  ml: {
                    md: 0,
                    sm: "100px",
                  },
                  height: "50%",
                  p: "1rem",
                  display: "flex",
                  flexDirection: {
                    md: "row",
                    xs: "column",
                  },
                  alignItems: "center",
                  justifyContent: "center",
                  ...(isChangePassword
                    ? {
                        width: {
                          lg: "60%",
                          md: "90%",
                          sm: "80%",
                          xs: "100%",
                        },
                      }
                    : {
                        width: {
                          lg: "50%",
                          md: "80%",
                          sm: "70%",
                          xs: "90%",
                        },
                      }),
                }}
              >
                {isChangePassword ? (
                  <Box>
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      onChange={handleChangeAvatarInput}
                      ref={InputAvatar}
                      style={{
                        display: "none",
                      }}
                    />
                    <Box sx={{ position: "relative" }}>
                      <Avatar
                        alt="Remy Sharp"
                        src={profileAvatar}
                        {...stringAvatar(user.name)}
                        sx={theme => ({
                          width: {
                            md: 120,
                            xs: 60,
                          },
                          height: {
                            md: 120,
                            xs: 60,
                          },
                          borderWidth: "1px",
                          borderColor: theme.palette.primary.main,
                          borderStyle: "solid",
                        })}
                      />
                      <Box
                        onClick={handleChangeAvatar}
                        sx={{
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          position: "absolute",
                          borderRadius: "50%",
                          top: 0,
                          left: 0,
                          "&:hover": {
                            opacity: 1,
                          },
                        }}
                      >
                        <FileUploadIcon sx={{ width: "30%", height: "30%" }} />
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}

                <Box
                  sx={{
                    width: {
                      lg: "100%",
                      xs: "100%",
                    },
                    p: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    ...(isChangePassword ? null : { alignItems: "center" }),
                  }}
                >
                  {isChangePassword ? (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          mb: "0.5rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ width: "40%", mb: 0 }}>
                          Имя пользователя:
                        </Typography>
                        <Input
                          disabled={isChangeData}
                          value={changedUserInfo?.name}
                          sx={{ width: "40%", m: 0, mr: "18%" }}
                          onChange={handleChange("name")}
                        />
                      </Box>

                      <Box
                        sx={{
                          width: "100%",
                          mb: "0.5rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ width: "40%", mb: 0 }}>
                          Организация:
                        </Typography>
                        <Input
                          disabled={isChangeData}
                          value={changedUserInfo?.organization}
                          sx={{ width: "40%", m: 0, mr: "18%" }}
                          onChange={handleChange("organization")}
                        />
                      </Box>

                      <Box
                        sx={{
                          width: "100%",
                          mb: "0.5rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ width: "40%", mb: 0 }}>
                          Email:
                        </Typography>
                        <Input
                          disabled={isChangeEmail}
                          value={changedUserInfo?.email}
                          sx={{
                            width: "40%",
                            m: 0,
                            ...(isChangeEmail
                              ? {
                                  mr: {
                                    lg: "1%",
                                    sm: "-2%",
                                  },
                                }
                              : { mr: "2%" }),
                          }}
                          onChange={handleChange("email")}
                        />
                        {isChangeEmail ? (
                          <IconButton
                            onClick={handleIsChangeEmail}
                            sx={{ mr: "12%" }}
                          >
                            <BorderColorIcon />
                          </IconButton>
                        ) : (
                          <Button
                            variant="outlined"
                            sx={{
                              height: "40px",
                              fontSize: "0.7rem",
                              mr: {
                                lg: "5%",
                                sm: "0%",
                              },
                            }}
                            onClick={handleSubmitVerifyEmail}
                          >
                            Верифицировать почту
                          </Button>
                        )}
                      </Box>

                      {activeUserKey ? (
                        <>
                          <Box
                            sx={{
                              width: "100%",
                              mb: "0.5rem",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography sx={{ width: "40%", mb: 0 }}>
                              Дата активации ключа:
                            </Typography>
                            <Input
                              disabled
                              type="date"
                              value={activeUserKey?.createdAt.split("T")[0]}
                              sx={{ width: "40%", m: 0, mr: "18%" }}
                            />
                          </Box>

                          <Box
                            sx={{
                              width: "100%",
                              mb: "0.5rem",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography sx={{ width: "40%", mb: 0 }}>
                              Осталось проверок:
                            </Typography>
                            <Input
                              disabled
                              value={activeUserKey?.calculationsRemained}
                              sx={{ width: "40%", m: 0, mr: "18%" }}
                            />
                          </Box>
                        </>
                      ) : (
                        <Box
                          sx={{
                            width: "100%",
                            mb: "0.5rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              width: "100%",
                              mt: 5,
                              mb: 0,
                              textAlign: "center",
                            }}
                          >
                            Нет активного ключа
                          </Typography>
                        </Box>
                      )}
                    </>
                  ) : (
                    <Box sx={{ width: "50%" }}>
                      <Box
                        sx={{
                          width: "100%",
                          padding: 0,
                          marginBottom: {
                            xs: "20px",
                          },
                        }}
                      >
                        <ShowableInput
                          inputLabel={"Старый пароль"}
                          id={"user-info-old-password"}
                          value={passwordInfo.password}
                          handleChange={handleChangePasswordFields("password")}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          padding: 0,
                          marginBottom: {
                            xs: "20px",
                          },
                        }}
                      >
                        <ShowableInput
                          inputLabel={"Новый пароль"}
                          id={"user-info-new-password"}
                          value={passwordInfo.newPassword}
                          handleChange={handleChangePasswordFields(
                            "newPassword"
                          )}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          padding: 0,
                          marginBottom: {
                            xs: "20px",
                          },
                        }}
                      >
                        <ShowableInput
                          inputLabel={"Повторите новый пароль"}
                          id={"user-info-new-password-repeated"}
                          value={passwordInfo.newPasswordRepeated}
                          handleChange={handleChangePasswordFields(
                            "newPasswordRepeated"
                          )}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box
                className="user-buttons-change"
                sx={{
                  width: {
                    md: "100%",
                    sm: "80%",
                    xs: "100%",
                  },
                  height: "50%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "50%",
                    display: "flex",
                    flexDirection: {
                      sm: "row",
                      xs: "column",
                    },
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  {isChangeData ? (
                    <Button
                      className="user-edit"
                      variant="outlined"
                      startIcon={<BorderColorIcon />}
                      onClick={handleIsChangeData}
                    >
                      Редактировать данные
                    </Button>
                  ) : (
                    <>
                      <Button
                        className="user-edit"
                        variant="outlined"
                        onClick={handleIsChangeData}
                      >
                        Отменить смену данных
                      </Button>

                      <Button
                        className="user-edit"
                        variant="outlined"
                        onClick={handleChangeData}
                      >
                        Подтвердить смену данных
                      </Button>
                    </>
                  )}
                  {isChangePassword ? (
                    <Button
                      className="user-edit"
                      variant="outlined"
                      startIcon={<PasswordIcon />}
                      onClick={handleIsChangePassword}
                    >
                      Сменить пароль
                    </Button>
                  ) : (
                    <>
                      <Button
                        className="user-edit"
                        variant="outlined"
                        onClick={handleIsChangePassword}
                      >
                        Отменить смену пароля
                      </Button>

                      <Button
                        className="user-edit"
                        variant="outlined"
                        onClick={handleSubmitChangePassword}
                      >
                        Подтвердить смену пароля
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            </>
          ) : (
            <Loader />
          )}
        </Box>
      </Box>

      <Modal
        open={isError}
        onClose={handleCloseIsError}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            height: "20%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={handleCloseIsError}
            sx={{
              width: 30,
              height: 30,
              position: "fixed",
              top: 10,
              right: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {error}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default ProfileView;
