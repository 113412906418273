import { useContext } from "react";
import { ClientApiContext } from "../providers/ApiProvider";

const useKeys = () => {
  const { api } = useContext(ClientApiContext);

  const getUserKeys = async (id, page, limit, sort) => {
    const response = await api.key.getUserKeys(id, page, limit, sort);

    return response;
  };

  const getUserActiveKey = async id => {
    const response = await api.key.getUserActiveKey(id);

    return response;
  };

  return { getUserKeys, getUserActiveKey };
};

export default useKeys;
