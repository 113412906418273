import React from "react";
import { Box, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function ModalWithButton({ open, closeFunction, styles, children }) {
  return (
    <Modal
      open={open}
      onClose={closeFunction}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          minHeight: "20%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          display: "flex",
          justifyContent: "center",
          ...styles,
        }}
      >
        <IconButton
          onClick={closeFunction}
          sx={{
            width: 30,
            height: 30,
            position: "fixed",
            top: 10,
            right: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box>{children}</Box>
      </Box>
    </Modal>
  );
}

export default ModalWithButton;
