import Layout from "../../layouts/Layout";
import UserTransactionsView from "../../views/transactions/UserTransactionsView";

const Transactions = () => {
  return (
    <Layout title={"Мои транзакции"}>
      <UserTransactionsView />
    </Layout>
  );
};

export default Transactions;
