import LayoutUnauthorize from "../../layouts/LayoutUnauthorize";
import LoginView from "../../views/LoginView";

const UserLogin = () => {
  return (
    <LayoutUnauthorize title={"Вход"}>
      <LoginView />
    </LayoutUnauthorize>
  );
};

export default UserLogin;
