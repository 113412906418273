import { useContext } from "react";
import { ClientApiContext } from "../providers/ApiProvider";

const useTransaction = () => {
  const { api } = useContext(ClientApiContext);

  const getUserTransactions = async (id, page, limit, sort) => {
    const response = await api.transaction.getUserTransactions(
      id,
      page,
      limit,
      sort
    );

    return response;
  };

  return {
    getUserTransactions,
  };
};

export default useTransaction;
