import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";

import {
  ActionKeyApi,
  AdminUserApi,
  AuthApi,
  FileApi,
  ProjectApi,
  TransactionApi,
  UserApi,
  AppContentApi,
} from "./generated";

export interface IApiClient {
  key: ActionKeyApi;
  admin: AdminUserApi;
  auth: AuthApi;
  project: ProjectApi;
  appContent: AppContentApi;
  user: UserApi;
  file: FileApi;
  transaction: TransactionApi;
  token?: string;
}

declare module "axios" {
  export interface AxiosRequestConfig {
    shouldSkipErrorHandling?: (response: AxiosResponse) => boolean;
  }
}

export class ApiClient implements IApiClient {
  key: ActionKeyApi;
  admin: AdminUserApi;
  auth: AuthApi;
  project: ProjectApi;
  appContent: AppContentApi;
  user: UserApi;
  file: FileApi;
  transaction: TransactionApi;
  currentToken?: string;
  accessToken?: string;
  refreshToken?: string;
  refreshTokens?: (token?: string) => Promise<void>;

  private readonly ax: AxiosInstance;

  constructor(baseUrl: string) {
    this.ax = axios.create({ baseURL: baseUrl });

    this.ax.interceptors.request.use(
      config => {
        if (this.currentToken) {
          config.headers.Authorization = `Bearer ${this.currentToken}`;
        }

        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    this.ax.interceptors.response.use(
      response => response,
      error => {
        if (error.response?.data.errorCode === "jwtExpired") {
          this.currentToken = this.refreshToken;
          return new Promise(resolve =>
            this.auth
              .refreshTokens()
              .then(async res => {
                if (this.refreshTokens) {
                  await this.refreshTokens(res.data.accessToken);
                  this.currentToken = res.data.accessToken;
                  if (error.config) {
                    error.config.headers.Authorization = `Bearer ${res.data.accessToken}`;
                    this.ax(error.config).then(r => resolve(r));
                  }
                }
              })
              .catch(async err => {
                if (this.refreshTokens) await this.refreshTokens();
              })
          );
        }
        return Promise.reject(error);
      }
    );

    this.key = new ActionKeyApi(undefined, baseUrl, this.ax);
    this.admin = new AdminUserApi(undefined, baseUrl, this.ax);
    this.auth = new AuthApi(undefined, baseUrl, this.ax);
    this.project = new ProjectApi(undefined, baseUrl, this.ax);
    this.appContent = new AppContentApi(undefined, baseUrl, this.ax);
    this.user = new UserApi(undefined, baseUrl, this.ax);
    this.transaction = new TransactionApi(undefined, baseUrl, this.ax);
    this.file = new FileApi(undefined, baseUrl, this.ax);
  }
}
