import { Navigate, Route, Routes } from "react-router-dom";
import Providers from "./providers/Providers";
import UserLogin from "./pages/authorization/login";
import UserRegistration from "./pages/authorization/registration";
import ResetPassword from "./pages/authorization/resetPassword";
import ForgotPassword from "./pages/authorization/forgotPassword";
import EmailVerify from "./pages/authorization/emailVerify";
import EmailChangeVerify from "./pages/authorization/changeEmailVerify";
import Home from "./pages";
import ProjectCard from "./pages/[projectId]";
import UserCard from "./pages/profile";
import Keys from "./pages/keys";
import Transactions from "./pages/transactions";
import ProtectedRoute from "./ProtectedRoutes";
import "./styles/globals.css";
import Questions from "./pages/questions";
import licenseAgreement from "./pages/licenseAgreement";

const App = () => {
  return (
    <Providers>
      <Routes>
        <Route path="/authorization/login" element={<UserLogin />} />
        <Route
          path="/authorization/registration"
          element={<UserRegistration />}
        />
        <Route
          path="/authorization/resetPassword"
          element={<ResetPassword />}
        />
        <Route
          path="/authorization/forgotPassword"
          element={<ForgotPassword />}
        />
        <Route path="/authorization/emailVerify" element={<EmailVerify />} />
        <Route
          path="/authorization/emailChangeVerify"
          element={<EmailChangeVerify />}
        />

        <Route path="/" element={<ProtectedRoute component={Home} />} />
        <Route
          path="/project/:id"
          element={<ProtectedRoute component={ProjectCard} />}
        />
        <Route
          path="/profile"
          element={<ProtectedRoute component={UserCard} />}
        />
        <Route path="/keys" element={<ProtectedRoute component={Keys} />} />
        <Route
          path="/transactions"
          element={<ProtectedRoute component={Transactions} />}
        />
        <Route
          path="/question"
          element={<ProtectedRoute component={Questions} />}
        />
        <Route
          path="/licenseAgreement"
          element={<ProtectedRoute component={licenseAgreement} />}
        />
        <Route path="/app/index.html" />

        <Route
          path="*"
          element={<Navigate to="/authorization/login" replace />}
        />
      </Routes>
    </Providers>
  );
};

export default App;
