import React from "react";
import { Paper } from "@mui/material";

const Status = ({ status }) => {
  const statusAsset = [
    {
      name: "Обработан",
      altName: "success",
      background: "green",
    },
    {
      name: "Прогресс",
      altName: "progress",
      background: "yellow",
    },
    {
      name: "Ошибка",
      altName: "error",
      background: "red",
    },
    {
      name: "Не расчитан",
      background: "gray",
    },
  ];

  const checkStatus = () =>
    statusAsset.find(function (item) {
      return item.name === status || item.altName === status;
    })?.background;

  return (
    <Paper
      sx={{
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        mx: "0.5rem",
        background: checkStatus,
      }}
    />
  );
};

export default Status;
