import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import useUser from "../hooks/useUser";

const ForgotPasswordView = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: "",
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSended, setIsSended] = useState(false);

  const { forgotPassword } = useUser();

  const handleChange = prop => event => {
    setError("");
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setError("");
    setIsLoading(true);

    await forgotPassword(values)
      .then(res => {
        setIsSended(true);
        setIsLoading(false);
        setTimeout(() => {
          navigate("/authorization/login");
        }, 2000);
      })
      .catch(res => {
        const errorsMessages = {
          400: "Пользователь не найден",
        };

        setError(errorsMessages[res.response.status]);
        setIsLoading(false);
      });
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#0D141E",
      }}
    >
      <Box
        sx={{
          width: {
            lg: "40vw",
            md: "60vh",
            sm: "80vw",
            xs: "100vw",
          },
          height: {
            sm: "35vh",
            xs: "45vh",
          },
          p: {
            sm: "40px",
            xs: "30px",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          background:
            "linear-gradient(269.39deg, rgba(25, 44, 61, 0.48) 3.53%, rgba(14, 44, 71, 0.48) 101.76%)",
        }}
      >
        {isSended ? (
          <Box
            sx={{
              display: "flex",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={theme => ({
                mb: "1rem",
                color: theme.palette.text.primary,
              })}
            >
              Ссылка для сброса пароля отправлена на {values.email}.
            </Typography>
          </Box>
        ) : (
          <>
            <Typography
              variant="h4"
              sx={theme => ({
                mb: "1rem",
                color: theme.palette.text.primary,
              })}
            >
              Забыл пароль
            </Typography>

            <Box
              sx={{
                width: "100%",
                height: {
                  sm: "80%",
                  xs: "70%",
                },
              }}
            >
              <form
                onSubmit={handleSubmit}
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    padding: 0,
                    marginBottom: {
                      xs: "20px",
                    },
                  }}
                >
                  <TextField
                    value={values.email}
                    label="Email"
                    variant="outlined"
                    placeholder={`Введите Email`}
                    sx={{
                      width: "100%",
                      backgroundColor: "#141d2b",
                    }}
                    InputProps={{
                      sx: {
                        fieldset: {
                          borderColor: "rgb(149 149 149 / 23%)",
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: "rgb(149 149 149)",
                      },
                    }}
                    onChange={handleChange("email")}
                  />
                </Box>

                <Box
                  sx={{
                    height: "10%",
                  }}
                >
                  {error && (
                    <Typography
                      variant="h7"
                      sx={{
                        mb: "1rem",
                        color: "red",
                      }}
                    >
                      {error}
                    </Typography>
                  )}
                </Box>

                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  type="submit"
                  sx={{
                    width: "60%",
                    maxHeight: "18%",
                    minHeight: "18%",
                    backgroundColor: "#42a5f5",
                  }}
                  color="primary"
                >
                  Отправить письмо
                </LoadingButton>
              </form>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ForgotPasswordView;
