import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";

const ShowableInput = ({ inputLabel, id, value, handleChange }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <FormControl
      sx={{ width: "100%", backgroundColor: "#141d2b" }}
      variant="outlined"
    >
      <InputLabel htmlFor={id} sx={{ color: "rgb(149 149 149)" }}>
        {inputLabel}
      </InputLabel>
      <OutlinedInput
        id={id}
        value={value}
        onChange={handleChange}
        type={showPassword ? "text" : "password"}
        placeholder={`Введите пароль`}
        sx={{ fieldset: { borderColor: "rgb(149 149 149 / 23%)" } }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={inputLabel}
      />
    </FormControl>
  );
};

export default ShowableInput;
