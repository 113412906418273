import LayoutUnauthorize from "../../layouts/LayoutUnauthorize";
import EmailVerifyView from "../../views/EmailVerifyView";

const EmailVerify = () => {
  return (
    <LayoutUnauthorize title={"Подтверждение почты"}>
      <EmailVerifyView />
    </LayoutUnauthorize>
  );
};

export default EmailVerify;
