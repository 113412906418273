import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import StyledTableCell from "../../components/TableCell/StyledTableCell";

const KeysTable = ({
  HeadRows,
  BodyRows,
  orderDirection,
  setOrderDirection,
  setDirection,
  valueToOrderBy,
  setValueToOrderBy,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}) => {
  const handleSortBy = item => {
    const isAscending = valueToOrderBy === item && orderDirection === "asc";
    setValueToOrderBy(item);
    setOrderDirection(isAscending ? "desc" : "asc");
    setDirection(isAscending ? "-1" : "1");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value), 10);
    setPage(1);
  };

  return (
    <>
      <TableContainer
        sx={{
          maxHeight: "75%",
          display: "flex",
          flexDirection: "column",
          alignItems: {
            lg: "center",
            sm: "normal",
          },
        }}
      >
        <Table stickyHeader sx={{ width: "80%", height: "90%" }}>
          <TableHead>
            <TableRow>
              {HeadRows.map((item, i) => (
                <StyledTableCell key={item.value}>
                  <TableSortLabel
                    active={valueToOrderBy === item.value}
                    direction={
                      valueToOrderBy === item.value ? orderDirection : "asc"
                    }
                    onClick={() => handleSortBy(item.value)}
                  >
                    {item.name}
                  </TableSortLabel>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {BodyRows?.results?.map((row, index) => (
              <TableRow key={row._id + index}>
                <StyledTableCell>{row.key}</StyledTableCell>
                <StyledTableCell>{row.calculationsCount}</StyledTableCell>
                <StyledTableCell>{row.calculationsRemained}</StyledTableCell>
                <StyledTableCell>{row.createdAt.split("T")[0]}</StyledTableCell>
                <StyledTableCell>{row.updatedAt.split("T")[0]}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component="div"
        count={BodyRows?.resultCount ? BodyRows?.resultCount : 0}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ display: "flex", justifyContent: "center" }}
      ></TablePagination>
    </>
  );
};

export default KeysTable;
