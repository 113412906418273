import { useContext, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import SideBar from "../components/SideBar/SideBar";
// import { Loader } from "../components/Loader/Loader";
// import { UserContext } from "../providers/UserProvider";

const Layout = ({ children, title }) => {
  // const navigate = useNavigate();
  // const { user, loading } = useContext(UserContext);

  // if (loading) {
  //   return <Loader />;
  // }

  // if (!user) {
  //   navigate("/authorization/login");
  // }

  const setTitle = (title) => {
    useEffect(() => {
      document.title = title;
    }, [title]);
  };

  return (
    <>
      {setTitle(title)}

      <Box
        sx={{
          display: "flex",
          flexFlow: "column nowrap",
        }}
      >
        <SideBar title={title} />
        {children}
      </Box>
    </>
  );
};

export default Layout;
