import { ThemeProvider, createTheme } from "@mui/material/styles";
import useUser from "../hooks/useUser";
import ClientApiProvider from "./ApiProvider";
import UserProvider from "./UserProvider";
import { Loader } from "../components/Loader/Loader";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const Providers = ({ children }) => {
  const { user } = useUser();

  return (
    <ThemeProvider theme={darkTheme}>
      <UserProvider>
        <ClientApiProvider>
          {user ? <>{children}</> : <Loader />}
        </ClientApiProvider>
      </UserProvider>
    </ThemeProvider>
  );
};

export default Providers;
