import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import useUser from "../hooks/useUser";
import { Loader } from "../components/Loader/Loader";

const ChangeEmailVerifyView = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const { changeEmail, resendVerifyCode } = useUser();

  useEffect(() => {
    setError("");
    setIsLoading(true);

    const queryBody = {
      verificationCode: searchParams.get("verificationCode"),
    };

    changeEmail(queryBody)
      .then(res => {
        setIsLoading(false);
      })
      .catch(res => {
        const errorsMessages = {
          400: "Ссылка повреждена или не актуальна",
          409: "Пользователь не найден",
          500: "Ошибка сервера",
        };

        setError(errorsMessages[res.response.status]);
        setIsLoading(false);
      })
      .finally(() => {
        setTimeout(() => {
          navigate("/authorization/login");
        }, 3000);
      });
  }, [searchParams]);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#0D141E",
      }}
    >
      <Box
        sx={{
          width: {
            lg: "40vw",
            md: "60vh",
            sm: "80vw",
            xs: "100vw",
          },
          height: {
            sm: "70vh",
            xs: "80vh",
          },
          p: {
            sm: "40px",
            xs: "30px",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          background:
            "linear-gradient(269.39deg, rgba(25, 44, 61, 0.48) 3.53%, rgba(14, 44, 71, 0.48) 101.76%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={theme => ({
              mb: "1rem",
              color: theme.palette.text.primary,
            })}
          >
            {isLoading ? (
              <Loader />
            ) : message ? (
              message
            ) : error ? (
              error
            ) : (
              `Email подтвержден`
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangeEmailVerifyView;
