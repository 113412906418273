import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import useUser from "../hooks/useUser";
import ShowableInput from "../components/ShowableInput/ShowableInput";

const ResetPasswordView = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [values, setValues] = useState({
    password: "",
    repeatedPassword: "",
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { resetPassword } = useUser();

  const handleChange = prop => event => {
    if (
      (prop === "password" || prop === "repeatedPassword") &&
      (event.target.value.match(/[а-я]+/) || event.target.value.match(/[А-Я]+/))
    ) {
      setError("Пароль не может содержать кирилицу");
    } else {
      setError("");
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setError("");
    setIsLoading(true);

    if (values.password === values.repeatedPassword) {
      const sendedData = {
        password: values.password,
        token: searchParams.get("resetPasswordCode"),
      };

      await resetPassword(sendedData)
        .then(res => {
          navigate("/authorization/login");
          setIsLoading(false);
        })
        .catch(res => {
          const errorsMessages = {
            400: "Неверные данные, время жизни токена сброса пароля истекло, пользователь не найден",
          };

          setError(errorsMessages[res.response.status]);
          setIsLoading(false);
        });
    } else {
      setError("Пароли не совпадают");
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#0D141E",
      }}
    >
      <Box
        sx={{
          width: {
            lg: "40vw",
            md: "60vh",
            sm: "80vw",
            xs: "100vw",
          },
          height: {
            sm: "50vh",
            xs: "50vh",
          },
          p: {
            sm: "40px",
            xs: "30px",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          background:
            "linear-gradient(269.39deg, rgba(25, 44, 61, 0.48) 3.53%, rgba(14, 44, 71, 0.48) 101.76%)",
        }}
      >
        <Typography
          variant="h4"
          sx={theme => ({
            mb: "1rem",
            color: theme.palette.text.primary,
          })}
        >
          Сброс пароля
        </Typography>

        <Box
          sx={{
            width: "100%",
            height: {
              sm: "80%",
              xs: "70%",
            },
          }}
        >
          <form
            onSubmit={handleSubmit}
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Box
              sx={{
                width: "100%",
                padding: 0,
                marginBottom: {
                  xs: "20px",
                },
              }}
            >
              <ShowableInput
                inputLabel={"Пароль"}
                id={"resetPassword-password"}
                value={values.password}
                handleChange={handleChange("password")}
              />
            </Box>

            <Box
              sx={{
                width: "100%",
                padding: 0,
                marginBottom: {
                  xs: "20px",
                },
              }}
            >
              <ShowableInput
                inputLabel={"Повторите пароль"}
                id={"resetPassword-repeatedPassword"}
                value={values.repeatedPassword}
                handleChange={handleChange("repeatedPassword")}
              />
            </Box>

            <Box
              sx={{
                height: "10%",
              }}
            >
              {error && (
                <Typography
                  variant="h7"
                  sx={{
                    mb: "1rem",
                    color: "red",
                  }}
                >
                  {error}
                </Typography>
              )}
            </Box>

            <LoadingButton
              loading={isLoading}
              variant="contained"
              type="submit"
              sx={{
                width: "60%",
                maxHeight: "15%",
                minHeight: "15%",
                backgroundColor: "#42a5f5",
              }}
              color="primary"
            >
              Сбросить
            </LoadingButton>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPasswordView;
