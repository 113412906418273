import LayoutUnauthorize from "../../layouts/LayoutUnauthorize";
import ChangeEmailVerifyView from "../../views/ChangeEmailVerifyView";

const EmailChangeVerify = () => {
  return (
    <LayoutUnauthorize title={"Подтверждение почты"}>
      <ChangeEmailVerifyView />
    </LayoutUnauthorize>
  );
};

export default EmailChangeVerify;
