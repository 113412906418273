import Layout from "../layouts/Layout";
import MyProjectsView from "../views/MyProjectsView";

export default function Home() {
  return (
    <Layout title={"Мои проекты"}>
      <MyProjectsView />
    </Layout>
  );
}
