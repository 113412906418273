import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import useUser from "../hooks/useUser";

const LayoutUnauthorize = ({ children, title }) => {
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    if (user) {
      if (JSON.stringify(user) !== "{}") {
        navigate("/");
      }
    }
  }, [user]);

  const setTitle = title => {
    useEffect(() => {
      document.title = title;
    }, [title]);
  };

  return (
    <>
      {setTitle(title)}

      <Box
        sx={{
          display: "flex",
          flexFlow: "column nowrap",
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default LayoutUnauthorize;
