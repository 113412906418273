import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Input,
  Modal,
  IconButton,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import FeedIcon from "@mui/icons-material/Feed";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import useProject from "../hooks/useProject";
import { Loader } from "../components/Loader/Loader";
import Status from "../components/Status/Status";

const ProjectView = ({ project, isError, setIsError, error, setError }) => {
  const navigate = useNavigate();
  const searchParams = useParams();
  const [isWait, setIsWait] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [downloadedData, setDownloadedData] = useState({
    name: "",
    state: null,
    resultsJSON: "",
    status: "",
  });
  const { deleteProject } = useProject();

  useEffect(() => {
    if (project) {
      setDownloadedData({
        name: project?.name,
        state: project?.state,
        status: project?.status,
      });
    }
  }, [project]);

  const downloadFile = () => {
    const fileName = project?.name;
    const json = JSON.stringify(downloadedData, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const handleCloseIsError = () => {
    setError("");
    setIsError(false);
  };

  const handleIsDelete = () => {
    setIsDelete(!isDelete);
  };

  const handleDelete = async () => {
    setIsWait(true);

    await deleteProject(searchParams.id)
      .then(res => {
        navigate("/");
        setIsWait(false);
      })
      .catch(res => {});
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: {
            sm: "70%",
            xs: "90%",
          },
          mt: {
            xs: "54px",
            md: "64px",
          },
          ml: {
            xs: "0px",
            md: "64px",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          background:
            "linear-gradient(269.39deg, rgba(25, 44, 61, 0.48) 3.53%, rgba(14, 44, 71, 0.48) 101.76%)",
        }}
      >
        {project ? (
          <>
            <Box
              className="project-info"
              sx={{
                width: {
                  md: "100%",
                  sm: "80%",
                  xs: "100%",
                },
                height: "50%",
                p: "1rem",
                display: "flex",
                flexDirection: {
                  md: "row",
                  xs: "column",
                },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FeedIcon
                alt="Project image"
                sx={theme => ({
                  width: {
                    md: 120,
                    xs: 60,
                  },
                  height: {
                    md: 120,
                    xs: 60,
                  },
                  color: theme.palette.primary.main,
                })}
              />

              <Box
                sx={{
                  width: {
                    lg: "70%",
                    md: "80%",
                    xs: "100%",
                  },
                  p: "1rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    mb: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ width: "40%", mb: 0 }}>
                    Название проекта:
                  </Typography>
                  <Input
                    disabled
                    value={project?.name}
                    sx={{ width: "40%", m: 0 }}
                  />
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    mb: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ width: "40%", mb: 0 }}>
                    Последнее обновление:
                  </Typography>
                  <Input
                    disabled
                    type="date"
                    value={project?.updatedAt.split("T")[0]}
                    sx={{ width: "40%", m: 0 }}
                  />
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    mb: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ width: "40%", mb: 0 }}>
                    Пользователь:
                  </Typography>
                  <Input
                    disabled
                    value={project?.ownerName}
                    sx={{ width: "40%", m: 0 }}
                  />
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    mb: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ width: "40%", mb: 0 }}>
                    Организация:
                  </Typography>
                  <Input
                    disabled
                    value={project?.organization}
                    sx={{ width: "40%", m: 0 }}
                  />
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    mb: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ width: "40%", mb: 0 }}>Статус:</Typography>

                  {project.status === "success" ? (
                    <Status status={"Обработан"} />
                  ) : project.status === "progress" ? (
                    <Status status={"Прогресс"} />
                  ) : !project.status ? (
                    <Status status={"Не расчитан"} />
                  ) : (
                    <Status status={"Ошибка"} />
                  )}

                  <Input
                    disabled
                    value={
                      project.status === "success"
                        ? "Обработан"
                        : project.status === "progress"
                        ? "Прогресс"
                        : !project.status
                        ? "Не расчитан"
                        : "Ошибка"
                    }
                    sx={{ width: "40%", m: 0 }}
                  />
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    mb: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ width: "40%", mb: 0 }}>Тип:</Typography>

                  <img
                    src={
                      project.type === "JSON"
                        ? "/images/icons/JSON.svg"
                        : "/images/icons/Excel.svg"
                    }
                    style={{ maxWidth: "30px", maxHeight: "30px" }}
                  />

                  <Input
                    disabled
                    value={project?.type}
                    sx={{ width: "40%", m: 0 }}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              className="project-links"
              sx={{
                width: {
                  md: "100%",
                  sm: "80%",
                  xs: "100%",
                },
                height: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "50%",
                  display: "flex",
                  flexDirection: {
                    sm: "row",
                    xs: "column",
                  },
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <LoadingButton
                  className="project-open"
                  variant="outlined"
                  startIcon={<FileOpenIcon />}
                  loading={isWait}
                  loadingPosition="start"
                  onClick={() => {
                    window.open(`/app/index.html?projectId=${project._id}`);
                  }}
                >
                  Открыть проект
                </LoadingButton>

                <LoadingButton
                  className="project-download"
                  variant="outlined"
                  startIcon={<DownloadIcon />}
                  loading={isWait}
                  loadingPosition="start"
                  onClick={downloadFile}
                >
                  Скачать проект
                </LoadingButton>

                <LoadingButton
                  className="project-download"
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  loading={isWait}
                  loadingPosition="start"
                  onClick={handleIsDelete}
                >
                  Удалить проект
                </LoadingButton>
              </Box>
            </Box>
          </>
        ) : (
          <Loader />
        )}
      </Box>

      <Modal
        open={isError}
        onClose={handleCloseIsError}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            height: "20%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={handleCloseIsError}
            sx={{
              width: 30,
              height: 30,
              position: "fixed",
              top: 10,
              right: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {error}
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={isDelete}
        onClose={handleIsDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: "50%",
            height: "25%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
          >
            Вы действительно хотите удалить этот проект?
          </Typography>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: {
                sm: "row",
                xs: "column",
              },
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <LoadingButton
              className="project-download"
              variant="outlined"
              startIcon={<CloseIcon />}
              loading={isWait}
              loadingPosition="start"
              onClick={handleIsDelete}
            >
              Отмена
            </LoadingButton>

            <LoadingButton
              className="project-download"
              variant="outlined"
              startIcon={<DeleteIcon />}
              loading={isWait}
              loadingPosition="start"
              onClick={handleDelete}
            >
              Удалить
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ProjectView;
