import Layout from "../../layouts/Layout";
import UserKeysView from "../../views/keys/UserKeysView";

const Keys = () => {
  return (
    <Layout title={`Мои ключи`}>
      <UserKeysView />
    </Layout>
  );
};

export default Keys;
