import LayoutUnauthorize from "../../layouts/LayoutUnauthorize";
import ForgotPasswordView from "../../views/ForgotPasswordView";

const ForgotPassword = () => {
  return (
    <LayoutUnauthorize title={"Забыл пароль"}>
      <ForgotPasswordView />
    </LayoutUnauthorize>
  );
};

export default ForgotPassword;
