import * as XLSX from "xlsx";
import lineTypes from "../electron/app/line_types.json";

const lineTypesToArray = () => {
  let result = [];
  Object.keys(lineTypes).forEach(type => {
    if (!result.includes(type.split("t")[0].trim())) {
      result.push(type.split("t")[0].trim().toLowerCase());
    }
  });

  return result;
};

const linesType = lineTypesToArray();

const createRandomHex = () =>
  ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, 0);

export const excelParsing = file => {
  const data = file;
  const workbook = XLSX.read(data, { type: "buffer" });
  let networkSheet;
  let elementsSheet;
  let stationValuesSheet;

  const elements = new Set();
  const lines = [];
  let temperature = 0;
  const errors = [];
  const listErrors = [];
  const warnings = [];

  const regExp = /[&\/\\#, +()$~%.'":*?<>{}]/g;

  // workbook.SheetNames.forEach((item, index) => {
  //   if (item.toLowerCase().indexOf("сеть") !== -1)
  //     networkSheet = workbook.Sheets[workbook.SheetNames[index]];
  //   if (item.toLowerCase().indexOf("потребители") !== -1)
  //     elementsSheet = workbook.Sheets[workbook.SheetNames[index]];
  //   if (item.toLowerCase().indexOf("подстанция") !== -1)
  //     stationValuesSheet = workbook.Sheets[workbook.SheetNames[index]];
  //   if (item.toLowerCase().indexOf("данные потребителей") !== -1)
  //     elementsValuesSheet = workbook.Sheets[workbook.SheetNames[index]];
  // });

  for (let index = 0; index <= workbook.SheetNames.length; index++) {
    const element = workbook.SheetNames[index];

    if (element && element?.toLowerCase().indexOf("сеть") !== -1) {
      networkSheet = workbook.Sheets[workbook.SheetNames[index]];
    } else if (index === 0) {
      networkSheet = workbook.Sheets[workbook.SheetNames[index]];
      listErrors.push({ title: `Первым должен быть лист "Cеть"` });
    }
    if (element && element?.toLowerCase().indexOf("потребители") !== -1) {
      elementsSheet = workbook.Sheets[workbook.SheetNames[index]];
    } else if (index === 1) {
      elementsSheet = workbook.Sheets[workbook.SheetNames[index]];
      listErrors.push({ title: `Вторым должен быть лист "Потребители"` });
    }
    if (element && element?.toLowerCase().indexOf("подстанция") !== -1) {
      stationValuesSheet = workbook.Sheets[workbook.SheetNames[index]];
    } else if (index === 2) {
      stationValuesSheet = workbook.Sheets[workbook.SheetNames[index]];
      listErrors.push({ title: `Третьим должен быть лист "Подстанция"` });
    }
  }

  const network_JSON = XLSX.utils.sheet_to_json(networkSheet, {
    header: 0,
    raw: false,
  });

  if (
    networkSheet?.A1?.v &&
    networkSheet?.B1?.v &&
    networkSheet?.C1?.v &&
    networkSheet?.D1?.v &&
    networkSheet?.E1?.v
  ) {
    if (
      networkSheet?.A1?.v.toString().toLowerCase().replace(regExp, "") !==
      "имяшины"
    ) {
      errors.push({
        title: `Не верное название столбца в ячейке A1 на 1 листе`,
      });
    }
    if (
      networkSheet?.B1?.v.toString().toLowerCase().replace(regExp, "") !==
      "имяшиныприсоединения"
    ) {
      errors.push({
        title: `Не верное название столбца в ячейке B1 на 1 листе`,
      });
    }
    if (
      networkSheet?.C1?.v.toString().toLowerCase().replace(regExp, "") !==
      "длиналиниим"
    ) {
      errors.push({
        title: `Не верное название столбца в ячейке C1 на 1 листе`,
      });
    }
    if (
      networkSheet?.D1?.v.toString().toLowerCase().replace(regExp, "") !==
      "маркакабеля"
    ) {
      errors.push({
        title: `Не верное название столбца в ячейке D1 на 1 листе`,
      });
    }
    if (
      networkSheet?.E1?.v.toString().toLowerCase().replace(regExp, "") !==
      "температурас°"
    ) {
      errors.push({
        title: `Не верное название столбца в ячейке E1 на 1 листе`,
      });
    }

    if (
      networkSheet?.E2?.v !== undefined &&
      Number.isInteger(networkSheet?.E2?.v)
    ) {
      temperature = networkSheet?.E2?.v;
    } else {
      warnings.push({
        sheet: "сеть",
        row: 2,
        field: "Температура",
      });

      temperature = 0;
    }

    network_JSON.forEach((item, index) => {
      let from;
      let to;
      let line_length;
      let mark;
      let markToCheck;
      const lcProperties = {};
      Object.keys(item).forEach(prop => {
        lcProperties[prop.toLowerCase()] = prop;
      });
      if (lcProperties["имя шины"]) {
        from = item[lcProperties["имя шины"]];
        elements.add(from);
      }
      if (lcProperties["имя шины присоединения"]) {
        to = item[lcProperties["имя шины присоединения"]];
        elements.add(to);
      }
      if (lcProperties["длина линии (м)"]) {
        line_length = Number(
          item[lcProperties["длина линии (м)"]].toString().replace(",", ".")
        );
        if (typeof line_length !== "number") {
          errors.push({
            sheet: "сеть",
            row: index + 2,
            column: 3,
          });
        }
      }
      if (lcProperties["марка кабеля"]) {
        const markRaw = item[lcProperties["марка кабеля"]];
        const [markFirst, markSecond] = markRaw.split(" ");

        mark = `${markFirst.toUpperCase()}${
          markSecond ? ` ${markSecond}` : ""
        }`;

        markToCheck = `${markFirst.toLowerCase()}${
          markSecond ? ` ${markSecond}` : ""
        }`;

        if (!linesType.includes(markToCheck)) {
          warnings.push({
            title: `На листе "Сеть" в строке ${
              index + 2
            } указана марка кабеля: ${markFirst.toUpperCase()}${
              markSecond ? ` ${markSecond}` : ""
            }, который отсутсвует в предложенных`,
            type: "mark",
          });
        }
      }

      if (index === 0 && from && (to || mark || line_length)) {
        errors.push({
          title: `На листе "Сеть" первой строкой значений должна быть тп с пустыми значениями.`,
        });
      }

      if (index !== 0) {
        if (!from) {
          errors.push({
            sheet: "Сеть",
            row: index + 2,
            field: "Имя шины",
          });
        }
        if (!to) {
          errors.push({
            sheet: "Сеть",
            row: index + 2,
            field: "Имя шины присоединения",
          });
        }
        if (!mark) {
          errors.push({
            sheet: "Сеть",
            row: index + 2,
            field: "Марка кабеля",
          });
        }
        if (!line_length) {
          errors.push({
            sheet: "Сеть",
            row: index + 2,
            field: "Длина линии",
          });
        }
      }

      if (from && to && mark && line_length) {
        const randomColor = createRandomHex();
        const id = randomColor;
        lines.push({
          id,
          from,
          to,
          line_length,
          mark,
          параметры: {
            длинаЛинии: line_length,
            маркаКабеля: mark,
          },
        });
      }
    });
    // } catch (error) {
    //   return {
    //     error: error.message,
    //   };
    // }
  } else {
    errors.push({
      title: `На 1 листе названия солбцов отсутствуют или не верны`,
    });
  }

  const nodesMap = new Map();

  const idToName = {};
  const nameToId = {};

  Array.from(elements).forEach((value, index) => {
    let type;
    if (index === 0) {
      type = "ЗакрытаяТП";
    } else {
      type = "ДеревяннаяОдностоечнаяОпора";
    }
    const randomColor = createRandomHex();
    const id = randomColor;

    idToName[id] = value;
    nameToId[value] = id;

    nodesMap.set(id, {
      id,
      имяШины: `${value}`,
      type,
      параметры: { имя: `${value}` },
    });
  });

  const elements_JSON = XLSX.utils.sheet_to_json(elementsSheet, {
    header: 0,
    raw: false,
  });

  let ТП = null;

  if (elementsSheet?.A1?.v && elementsSheet?.B1?.v) {
    if (
      elementsSheet?.A1?.v.toString().toLowerCase().replace(regExp, "") !==
      "имяшины"
    ) {
      errors.push({
        title: `Не верное название столбца в ячейке A1 на 2 листе`,
      });
    }
    if (
      elementsSheet?.B1?.v.toString().toLowerCase().replace(regExp, "") !==
      "энергияквтч"
    ) {
      errors.push({
        title: `Не верное название столбца в ячейке B1 на 2 листе`,
      });
    }
    // try {
    elements_JSON.forEach(el => {
      if (!el["Имя шины"]) {
        errors.push({
          sheet: "Потребители",
          row: el.__rowNum__ + 1,
          field: "Имя шины",
        });
      }

      const id = nameToId[el["Имя шины"]];

      if (nodesMap.has(id)) {
        const node = nodesMap.get(id);
        if (!el["Энергия (кВт*ч)"]) {
          errors.push({
            sheet: "Потребители",
            row: el.__rowNum__ + 1,
            field: "Энергия (кВт*ч)",
          });
        }
        node["мощность"] = el["Энергия (кВт*ч)"];
        node.type = "ЖилойДом";
        node.параметры.мощность = node["мощность"];
      }
    });

    ТП = Array.from(nodesMap.values()).find(node => node.type === "ЗакрытаяТП");

    if (elements_JSON.length === 0) {
      errors.push({
        title: `Не достает данных по потребителям.`,
      });
    }
  } else {
    errors.push({
      title: `На 2 листе названия солбцов отсутствуют или не верны`,
    });
  }

  const station_JSON = XLSX.utils.sheet_to_json(stationValuesSheet, {
    header: 1,
    raw: false,
  });

  if (
    stationValuesSheet?.A3?.v &&
    stationValuesSheet?.B3?.v &&
    stationValuesSheet?.C3?.v
  ) {
    if (
      stationValuesSheet?.A3?.v.toString().toLowerCase().replace(regExp, "") !==
      "время"
    ) {
      errors.push({
        title: `Не верное название столбца в ячейке A3 на 3 листе`,
      });
    }
    if (
      stationValuesSheet?.B3?.v.toString().toLowerCase().replace(regExp, "") !==
      "активнаямощностьквт"
    ) {
      errors.push({
        title: `Не верное название столбца в ячейке B3 на 3 листе`,
      });
    }
    if (
      stationValuesSheet?.C3?.v.toString().toLowerCase().replace(regExp, "") !==
      "реактивнаямощностьквар"
    ) {
      errors.push({
        title: `Не верное название столбца в ячейке C3 на 3 листе`,
      });
    }
    const datetimeObj = {};
    station_JSON.forEach((val, index) => {
      const [datetime, active, reactive] = val;
      // console.log(datetime, active, reactive);
      if (val.length !== 0 && index > 2) {
        if (!datetime) {
          errors.push({
            sheet: "Подстанция",
            row: index + 1,
            field: "Время",
          });
        }
        if (!active) {
          errors.push({
            sheet: "Подстанция",
            row: index + 1,
            field: "Активная мощность (кВт)",
          });
        }
        if (!reactive) {
          errors.push({
            sheet: "Подстанция",
            row: index + 1,
            field: "Реактивная мощность (кВар)",
          });
        }
      }

      if (datetime) {
        const [date, time] = datetime.split(" ");
        if (!datetimeObj[date]) {
          datetimeObj[date] = [];
        }
        datetimeObj[date].push([time, active, reactive]);
      }
    });

    const измеренияМощности = [];
    let первоеИзмерение48 = Object.entries(datetimeObj).find(([date, time]) => {
      if (time.length == 48) {
        return true;
      }
      return false;
      // console.log(date);
    });

    // console.log("первое",первоеИзмерение48);
    // try {
    if (первоеИзмерение48) {
      if (первоеИзмерение48[1]) {
        первоеИзмерение48 = первоеИзмерение48[1];
      }
      if (Array.isArray(первоеИзмерение48)) {
        первоеИзмерение48.forEach(измерение => {
          измеренияМощности.push({
            активнаяМощность: Number(
              измерение[1].toString().replace(",", ".")
            ).toFixed(2),
            реактивнаяМощность: Number(
              измерение[2].toString().replace(",", ".")
            ).toFixed(2),
          });
        });
        if (ТП) {
          ТП.измеренияМощности = измеренияМощности;
        } else {
          errors.push({
            title: `Нет данных по ТП.`,
          });
          // throw new Error(`Нет данных по ТП`);
        }
      }
    } else {
      const checkUncorrcetDateAndTime = Object.entries(datetimeObj).some(
        ([date, time]) => date.split(":").length === 2
      );

      if (checkUncorrcetDateAndTime) {
        errors.push({
          title: `Ошибка заполнения данных подстанции: Не верный формат даты и времени.`,
        });
      } else {
        Object.entries(datetimeObj).find(([date, time]) => {
          if (time.length !== 48 && date.split(".").length === 3) {
            // console.log(date);
            errors.push({
              title: `Ошибка заполнения данных подстанции: Заполнено ${time.length} из 48 получасовок ${date}.`,
            });
          }
        });
      }

      // throw new Error(
      //   `Не хватает данных для расчётов сети. Нет ни одного дня с полностью заполнеными получасовками.`
      // );
    }
    // } catch (error) {
    //   return {
    //     error: error.message,
    //   };
    // }
  } else {
    errors.push({
      title: `На 3 листе названия солбцов отсутствуют или не верны`,
    });
  }

  if (stationValuesSheet?.A2?.v && stationValuesSheet?.B2?.v) {
    const [name, value] = station_JSON[1];
    if (
      name.toString().toLowerCase().replace(regExp, "") !== "класснапряжения"
    ) {
      errors.push({
        title: `Не верное название параметра в ячейке A2 на 3 листе`,
      });
    }

    if (Number(value) >= 0.4 && Number(value) <= 2) {
      ТП.параметры.классНапряжения = value;
      ТП.translation = [0, 0];
    } else {
      errors.push({
        title: `Значение параметра "Класс напряжения" на листе 3 должно быть в пределах от 0.4 до 2`,
      });
    }
  } else {
    errors.push({
      title: `Отсутствует параметр "Класс напряжения" на 3 листе`,
    });
  }

  if (errors.length === 0) {
    const построитьСеть = (node, exclude) => {
      const filteredLines = lines.filter(line => {
        if (
          (line.from == node.имяШины || line.to == node.имяШины) &&
          line.from != exclude?.имяШины &&
          line.to != exclude?.имяШины
        ) {
          return true;
        } else return false;
      });

      filteredLines.forEach((line, index) => {
        let anotherNode;
        anotherNode = nodesMap.get(
          nameToId[line.from == node.имяШины ? line.to : line.from]
        );
        line.node1 = node.id;
        line.node2 = anotherNode.id;
        if (!node.child) {
          node.child = [];
        }
        if (!node.sin) {
          node.sin = 0;
        }
        if (!node.cos) {
          node.cos = 1;
        }
        anotherNode.sin = Math.sin(
          (index * Math.PI) / filteredLines.length +
            (node.sin != 0 ? Math.PI / filteredLines.length / 2 : 0)
        );
        anotherNode.cos = Math.cos(
          (index * Math.PI) / filteredLines.length +
            (node.cos != 1 ? Math.PI / filteredLines.length / 2 : 0)
        );
        anotherNode.translation = [
          node.translation[0] + line.line_length * (anotherNode.cos + node.cos),
          node.translation[1] + line.line_length * (anotherNode.sin + node.sin),
        ];
        const result = построитьСеть(anotherNode, node);
        node.child.push({
          ...result,
          line_length: line.line_length,
          mark: line.mark,
        });
      });

      return node;
    };

    const result = построитьСеть(ТП);
  }

  if (errors.length !== 0) {
    if (listErrors.length !== 0) {
      listErrors.reverse().map(error => {
        errors.unshift(error);
      });
    }

    return { error: errors };
  } else {
    if (warnings.length === 0) {
      return {
        узлы: Array.from(nodesMap.values()),
        провода: lines,
        температура: temperature,
      };
    } else {
      return {
        узлы: Array.from(nodesMap.values()),
        провода: lines,
        температура: temperature,
        warnings: warnings,
      };
    }
  }
};
