import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import useUser from "../../hooks/useUser";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FeedIcon from "@mui/icons-material/Feed";
import KeyIcon from "@mui/icons-material/Key";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import PersonIcon from "@mui/icons-material/Person";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import LogoutIcon from "@mui/icons-material/Logout";
import { Loader } from "../../components/Loader/Loader";

const drawerWidth = 300;

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = theme => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(0px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== "open",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideBar = ({ title }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [items, setItems] = useState([]);
  const { user, logout } = useUser();

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const menuItems = [
    {
      pageName: "Мой профиль",
      link: "/profile",
      icon: <PersonIcon />,
    },
  ];

  const menuUserItems = [
    {
      pageName: "Мои проекты",
      link: "/",
      icon: <FeedIcon />,
    },
    {
      pageName: "Мои ключи",
      link: "/keys",
      icon: <KeyIcon />,
    },
    {
      pageName: "Мои транзакции",
      link: "/transactions",
      icon: <SwapHorizIcon />,
    },
    {
      pageName: "Обратная связь",
      link: "/question",
      icon: <QuestionMarkIcon />,
    },
  ];

  useEffect(() => {
    if (user) {
      setItems([...menuItems, ...menuUserItems]);
    } else {
      setItems([...menuItems]);
    }
  }, [user]);

  const handleLogout = async e => {
    e.preventDefault();

    setIsLoading(true);

    await logout()
      .then(res => {
        navigate("/authorization/login");
      })
      .catch(res => {});
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader />
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <List>
            {items.map(item => (
              <ListItem key={item.link} disablePadding>
                <ListItemButton component="a" href={item.link}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.pageName} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          <List>
            {user && (
              <ListItem
                disablePadding
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "flex-end",
                  justifySelf: "flex-end",
                }}
              >
                <ListItemButton
                  sx={{
                    width: "100%",
                    minHeight: "10%",
                    maxHeight: "10%",
                  }}
                  component="a"
                  href="/licenseAgreement"
                >
                  <ListItemIcon>
                    <HistoryEduIcon />
                  </ListItemIcon>
                  <ListItemText primary="Лицензионное соглашение" />
                </ListItemButton>

                <ListItemButton
                  onClick={handleLogout}
                  disabled={isLoading}
                  sx={{
                    width: "100%",
                    minHeight: "10%",
                    maxHeight: "10%",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      ...(isLoading
                        ? { scale: "0.5", marginLeft: "-8px" }
                        : null),
                    }}
                  >
                    {isLoading ? <Loader /> : <LogoutIcon />}
                  </ListItemIcon>
                  <ListItemText primary="Выход" />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default SideBar;
