import { useContext } from "react";
import { ClientApiContext } from "../providers/ApiProvider";

const useAppContent = () => {
  const { api } = useContext(ClientApiContext);

  const getLicense = async () => {
    const response = await api.appContent.getLicense();

    return response;
  };

  return {
    getLicense,
  };
};

export default useAppContent;
