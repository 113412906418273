import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

function QuestionsView() {
  const [values, setValues] = useState({
    title: "",
    description: "",
  });

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSend = () => {
    console.log("Введенные значения", values);
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: {
            sm: "70%",
            xs: "90%",
          },
          mt: {
            xs: "54px",
            md: "64px",
          },
          ml: {
            xs: "0px",
            md: "64px",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          background:
            "linear-gradient(269.39deg, rgba(25, 44, 61, 0.48) 3.53%, rgba(14, 44, 71, 0.48) 101.76%)",
        }}
      >
        <form
          style={{
            width: "90%",
            height: "90%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Typography variant="h6" component="h2">
            Задайте свой вопрос
          </Typography>

          <Box
            sx={{
              width: {
                lg: "50%",
                sm: "80%",
              },
              height: "70%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <TextField
              label="Тема вопроса"
              variant="outlined"
              placeholder="Тема вопроса"
              inputProps={{
                autoComplete: "off",
              }}
              sx={{ width: "50%" }}
              onChange={handleChange("title")}
            />

            <TextField
              placeholder="Описание"
              multiline
              minRows={8}
              maxRows={10}
              inputProps={{
                autoComplete: "off",
              }}
              sx={{ width: "100%" }}
              onChange={handleChange("description")}
            />
          </Box>

          <Button
            variant="outlined"
            startIcon={<SendIcon />}
            onClick={handleSend}
          >
            Отправить
          </Button>
        </form>
      </Box>
    </Box>
  );
}

export default QuestionsView;
