import Layout from '../layouts/Layout';
import LicenseAgreementView from "../views/LicenseAgreementView";

const licenseAgreement = () => {
  return (
    <Layout title={`Лицензионное соглашение`}>
      <LicenseAgreementView />
    </Layout>
  );
}

export default licenseAgreement