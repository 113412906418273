import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useUser from "../hooks/useUser";
import Layout from "../layouts/Layout";
import ProfileView from "../views/ProfileView";
import useKeys from "../hooks/useKeys";

const UserCard = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [activeKey, setActiveKey] = useState(null);
  const { user, getUserInfo } = useUser();
  const { getUserActiveKey } = useKeys();

  useEffect(() => {
    if (user) {
      getUserInfo()
        .then(res => {
          setUserInfo(res.data);
        })
        .catch(res => {
          navigate("/authorization/login");
        });
    }
  }, [user]);

  useEffect(() => {
    if (userInfo) {
      getUserActiveKey(userInfo._id)
        .then(res => {
          setActiveKey(res.data);
        })
        .catch(res => {});
    }
  }, [userInfo]);

  return (
    <Layout
      title={`Пользователь: ${userInfo?.name ? userInfo?.name : "Загрузка..."}`}
    >
      <ProfileView
        user={userInfo}
        setUserInfo={setUserInfo}
        activeUserKey={activeKey}
      />
    </Layout>
  );
};

export default UserCard;
