import LayoutUnauthorize from "../../layouts/LayoutUnauthorize";
import RegistrationView from "../../views/RegistrationView";

const UserRegistration = () => {
  return (
    <LayoutUnauthorize title={"Регистрация"}>
      <RegistrationView />
    </LayoutUnauthorize>
  );
};

export default UserRegistration;
