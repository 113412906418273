import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../layouts/Layout";
import useUser from "../hooks/useUser";
import useProject from "../hooks/useProject";
import ProjectView from "../views/ProjectView";

const ProjectCard = () => {
  const searchParams = useParams();
  const [project, setProject] = useState(null);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const { user } = useUser();
  const { getProject } = useProject();

  useEffect(() => {
    if (searchParams.id && user) {
      getProject(searchParams.id)
        .then(res => {
          setError("");
          setIsError(false);
          setProject(res.data);
        })
        .catch(res => {
          const errorsMessages = {
            401: "Неверные данные для авторизации",
            400: "Проект не найден",
            404: "Проект не найден",
          };

          setError(errorsMessages[res?.response?.status]);
          setIsError(true);
        });
    }
  }, [searchParams.id, user]);

  return (
    <Layout title={`Проект: ${project?.name ? project?.name : "Загрузка..."}`}>
      <ProjectView
        project={project}
        isError={isError}
        setIsError={setIsError}
        error={error}
        setError={setError}
      />
    </Layout>
  );
};

export default ProjectCard;
