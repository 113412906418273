import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import DOMPurify from "dompurify";
import useAppContent from "../hooks/useAppContent";

const LicenseAgreement = () => {
  const [licenseText, setLicenseText] = useState("");
  const { getLicense } = useAppContent();

  useEffect(() => {
    getLicense()
      .then(res => {
        setLicenseText(res.data.licenseText);
      })
      .catch(res => {});
  }, []);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "80%",
          mt: {
            xs: "54px",
            md: "64px",
          },
          ml: {
            xs: "0px",
            md: "64px",
          },
          background: `linear-gradient(269.39deg, rgba(25, 44, 61, 0.48) 3.53%, rgba(14, 44, 71, 0.48) 101.76%)`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "90%",
            height: "90%",
            overflow: "auto",
          }}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(licenseText) }}
        ></Box>
      </Box>
    </Box>
  );
};

export default LicenseAgreement;
