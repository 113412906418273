import React from 'react'
import Layout from '../layouts/Layout';
import QuestionsView from '../views/QuestionsView';

function Questions() {
  return (
    <Layout title={`Обратная связь`}>
      <QuestionsView />
    </Layout>
  );
}

export default Questions