import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { ClientApiContext } from "../providers/ApiProvider";

const useUser = () => {
  const { user, setUser } = useContext(UserContext);
  const { api } = useContext(ClientApiContext);

  const login = async values => {
    const response = await api.auth.signInEmail(values);
    if (response.data) {
      setUser(response.data);
    }

    return response;
  };

  const registration = async values => {
    const response = await api.auth.signUpUser(values);
    return response;
  };

  const forgotPassword = async data => {
    const response = await api.auth.forgotPassword(data);

    return response;
  };

  const resetPassword = async data => {
    const response = await api.auth.resetPassword(data);

    return response;
  };

  const emailVerification = async data => {
    const response = await api.auth.verifyUserEmail(data);

    return response;
  };

  const resendVerifyCode = async data => {
    const response = await api.auth.resendVerifyCode(data);

    return response;
  };

  const refreshTokens = async token => {
    if (token) {
      setUser({
        ...user,
        accessToken: token,
      });
    } else {
      setUser({});
    }
  };

  const getUserInfo = async () => {
    const response = await api.auth.getUserInfo();

    return response;
  };

  const logout = async () => {
    setUser({});
  };

  const updateProfile = async data => {
    const response = await api.user.updateProfile(data);

    return response;
  };

  const sendChangeEmail = async data => {
    const response = await api.user.sendChangeEmail(data);

    return response;
  };

  const changeEmail = async code => {
    const response = await api.user.changeEmail(code);

    return response;
  };

  const uploadAvatar = async file => {
    const response = await api.user.uploadAvatar(file);

    return response;
  };

  const changePassword = async values => {
    const response = await api.user.changePassword(values);

    return response;
  };

  return {
    user,
    login,
    registration,
    forgotPassword,
    resetPassword,
    emailVerification,
    resendVerifyCode,
    refreshTokens,
    getUserInfo,
    logout,
    updateProfile,
    sendChangeEmail,
    changeEmail,
    uploadAvatar,
    changePassword,
  };
};

export default useUser;
