import { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { Box, Typography, Checkbox, FormControlLabel } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import useAppContent from "../../hooks/useAppContent";

const LicenseAgreement = ({
  isLoading,
  checked,
  handleChangeChecked,
  error,
  handleCloseModal,
  handleSubmit,
}) => {
  const [licenseText, setLicenseText] = useState("");
  const { getLicense } = useAppContent();

  useEffect(() => {
    getLicense()
      .then(res => {
        setLicenseText(res.data.licenseText);
      })
      .catch(res => {});
  }, []);

  return (
    <Box
      sx={{
        width: "60vw",
        height: "80vh",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ display: "flex", alignItems: "center", color: "#fff" }}
      >
        Лицензионное соглашение
      </Typography>

      <Box
        sx={{
          width: "90%",
          height: "70%",
          overflow: "hidden",
          color: "#d6d4d4",
        }}
      >
        <Box
          sx={{
            p: "1rem",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflowY: "auto",
            backgroundColor: "#181818",
          }}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(licenseText) }}
        >
          {/* <Typography sx={{ m: "10px 0px" }}>ЛИЦЕНЗИЯ НА ПРОГРАММУ</Typography>

          <p>
            Программа защищена законами и международными соглашениями об
            авторских правах, а также другими законами и договорами,
            регулирующими отношения авторского права. Программа лицензируется, а
            не продается.
          </p>

          <Typography sx={{ m: "10px 0px" }}>1. ОБЪЕМ ЛИЦЕНЗИИ.</Typography>

          <p>
            Настоящее соглашение дает вам нижеследующие права:
            <br /> 1.1. Использование программы. Разрешается установка
            одновременно на одном компьютере программы или любой предыдущей
            версии. Основному пользователю компьютера, на котором установлена
            эта копия, разрешается также создание еще одной копии исключительно
            для своей работы на переносном компьютере.
            <br /> 1.2. Хранение и использование в сети. Разрешается хранение,
            установка и запуск копии программы с общедоступного устройства
            хранения данных (например, сервера сети). При этом для каждого
            компьютера, на котором установлена или запущена с сервера сети
            данная программа, необходимо приобрести отдельную лицензию. Лицензия
            на программу не допускает совместного или одновременного
            использования программы на разных компьютерах в количестве более
            указанного в п. 1.1.
          </p>

          <Typography sx={{ m: "10px 0px" }}>
            2. ОПИСАНИЕ ПРОЧИХ ПРАВ И ОГРАНИЧЕНИЙ.
          </Typography>

          <p>
            2.1. Ограничения на вскрытие технологии, декомпиляцию и
            дизассемблирование. Не разрешается осуществлять вскрытие технологии,
            декомпиляцию и дизассемблирование программы, за исключением и только
            в той степени, в которой такие действия явно разрешены действующим
            законодательством, несмотря на наличие в соглашении данного
            ограничения.
            <br /> 2.2. Разделение программы. Программа лицензируется как единое
            целое. Ее нельзя разделять на составляющие части для использования
            на нескольких компьютерах.
            <br /> 2.3. Прокат. Не разрешается предоставлять программу в прокат
            или во временное пользование.
            <br /> 2.4. Услуги по технической поддержке. Автор оказывает услуги
            по технической поддержке программных продуктов (далее "услуги по
            технической поддержке"). Обращение к Автору за технической
            поддержкой осуществляется по e-mail: _____________ , по телефону:
            ____________ или ICQ __________. Любые дополнительные программы и
            исходные тексты, переданные вам в результате оказания услуг по
            технической поддержке, должны рассматриваться как составная часть
            программы и подпадают, таким образом, под действие ограничений и
            условий данного соглашения. Технические данные, которые сообщаются
            службе технической поддержки в ходе обращения, могут быть
            использованы Автором для внутренних целей, включая техническую
            поддержку программных продуктов и разработку программного
            обеспечения. Автор не будет использовать данные сведения в форме,
            раскрывающей ваши личные сведения.
            <br /> 2.5. Передача программы. Разрешается навсегда уступить все
            свои права по настоящему соглашению только вместе с продажей или
            передачей компьютера при условии, что Вы не сохраняете никаких
            копий, передаете всю программу (включая все составные части,
            носители и печатные материалы, любые обновления, настоящее
            соглашение и сертификаты подлинности, если таковые имеются), а
            получатель соглашается на условия данного соглашения. Если программа
            является обновлением ("upgrade"), то любая передача должна включать
            в себя все предыдущие версии программы.
            <br /> 2.6. Прекращение действия соглашения. Без ущерба для любых
            других своих прав Автор может прекратить действие настоящего
            соглашения при несоблюдении условий и ограничений данного
            соглашения, что обяжет вас уничтожить все имеющиеся копии и
            составляющие части программы.
          </p>

          <Typography sx={{ m: "10px 0px" }}>3. АВТОРСКОЕ ПРАВО.</Typography>

          <p>
            3.1. Все права собственности и авторские права на программу (в том
            числе любые включенные в нее управляющие программы (applets),
            фотографии, анимации, видео- и звукозаписи, музыку и текст),
            компоненты ActiveX, сопровождающие ее печатные материалы и любые
            копии программы принадлежат Автору. Все права Автора на программу
            защищены законами и международными соглашениями об авторских правах,
            а также другими законами и договорами, регулирующими отношения
            авторского права. Следовательно, с программой необходимо обращаться,
            как с любым другим объектом авторского права, с тем лишь
            исключением, что программу разрешается установить на один компьютер
            и сохранить оригинал при условии, что он будет использоваться только
            как архив или резервная копия. Копирование сопровождающих программу
            печатных материалов запрещено.
          </p>

          <Typography sx={{ m: "10px 0px" }}>
            4. РАЗЛИЧНЫЕ НОСИТЕЛИ ПРОГРАММ.
          </Typography>

          <p>
            4.1. Программа может поставляться на нескольких видах носителей, а
            также по сети Internet исключительно с Web-сайта Автора. Независимо
            от их вида и емкости разрешается использовать только носители одного
            вида, который соответствует именно вашему компьютеру или серверу
            сети. Не разрешается производить установку с прочих носителей на
            другие компьютеры, предоставлять носители в прокат или во временное
            пользование или уступать их для использования в иных целях, за
            исключением случая полной передачи программного обеспечения,
            описанного выше.
          </p> */}
        </Box>
      </Box>

      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChangeChecked} />}
        label="Я принимаю условия Пользовательского соглашения и даю своё согласие на обработку моей персональной информации"
        sx={{
          color: "#fff",
          m: 0,
        }}
      />

      <Box
        sx={{
          height: "5%",
        }}
      >
        {error && (
          <Typography
            variant="h7"
            sx={{
              mb: "1rem",
              color: "red",
            }}
          >
            {error}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "10%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <LoadingButton
          loading={isLoading}
          variant="contained"
          type="button"
          sx={{
            width: "30%",
            backgroundColor: "#f56042",
            color: "#000",

            ":hover": {
              backgroundColor: "#f56042",
            },
          }}
          color="error"
          onClick={handleCloseModal}
        >
          Отмена
        </LoadingButton>

        <LoadingButton
          loading={isLoading}
          variant="contained"
          disabled={!checked}
          type="button"
          sx={{
            width: "30%",
            backgroundColor: "#42a5f5",
          }}
          color="primary"
          onClick={handleSubmit}
        >
          Принять
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default LicenseAgreement;
