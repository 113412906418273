import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Box, Modal, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import useUser from "../hooks/useUser";
import useProject from "../hooks/useProject";
import { excelParsing } from "../middleware/NewExcelParsing";
import ProjectsTable from "../components/ProjectsTable/ProjectsTable";
import { Loader } from "../components/Loader/Loader";
import ModalWithButton from "../components/ModalWithButton/ModalWithButton";

const MyProjectsView = () => {
  const navigate = useNavigate();
  const InputExcel = useRef(null);
  const InputJSON = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [userProjects, setUserProjects] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState("");
  const [projectOpenId, setProjectOpenId] = useState(null);
  // const [file, setFile] = useState("");

  const [orderDirection, setOrderDirection] = useState("asc");
  const [direction, setDirection] = useState(1);
  const [valueToOrderBy, setValueToOrderBy] = useState("name");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const today = new Date().toISOString();

  const itemsTableHeadRows = [
    {
      name: "Имя",
      value: "name",
    },
    {
      name: "Последнее обновление",
      value: "updatedAt",
    },
    {
      name: "Организация",
      value: "organization",
    },
    {
      name: "Статус",
      value: "status",
    },
  ];

  const { user, getUserInfo } = useUser();
  const { fetchUserProjects, createProject } = useProject();

  useEffect(() => {
    if (user) {
      getUserInfo()
        .then(res => {
          setUserInfo(res.data);
        })
        .catch(res => {
          navigate("/authorization/login");
        });
    }
  }, [user]);

  useEffect(() => {
    if (userInfo) {
      fetchUserProjects(
        userInfo?._id,
        page,
        rowsPerPage,
        JSON.stringify({
          [valueToOrderBy]: direction,
        })
      )
        .then(res => {
          setUserProjects(res.data);
          setIsError(false);
          setIsLoading(false);
        })
        .catch(res => {
          const errorsMessages = {
            401: "Неверные данные для авторизации",
            404: "Проекты не найдены",
          };

          setError(errorsMessages[res.response.status]);
          setUserProjects({});
          setIsError(true);
          setIsLoading(false);
        });
    }
  }, [userInfo, valueToOrderBy, orderDirection, page, rowsPerPage, isLoading]);

  const handleOpenCreate = () => {
    setIsCreate(true);
    setProjectName("");
    setError("");
  };

  const handleCloseCreate = () => {
    setIsCreate(false);
    setProjectName("");
    setError("");
  };

  const handleCloseIsError = () => {
    setError("");
    setIsError(false);
    setIsLoading(true);
    if (projectOpenId) {
      window.location.href = `/app/index.html?projectId=${projectOpenId}`;
    }
  };

  const handleChange = e => {
    setProjectName(e.target.value);
    setError("");
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);

    const project = {
      name: projectName,
      type: "JSON",
      state: {},
      temperature: 0,
    };

    await createProject(project)
      .then(res => {
        setError("");
        setIsLoading(true);
        setIsCreate(false);
        setProjectName("");
        window.location.href = `/app/index.html?projectId=${res.data._id}`;
      })
      .catch(res => {
        const errorsMessages = {
          400: "Введите название проекта",
          401: "Неверные данные для авторизации",
        };

        setError(errorsMessages[res.response.status]);
        setIsError(true);
      });
  };

  const handleUploadJSON = () => {
    InputJSON.current.click(), "fileUpload";
  };

  const handleUploadExcel = () => {
    InputExcel.current.click(), "fileUpload";
  };

  const handleCreateExcel = async e => {
    const fileBuffer = await e.target.files[0]?.arrayBuffer();
    let response = null;

    if (fileBuffer) {
      response = await excelParsing(fileBuffer);
    } else {
      setError("Файл не выбран");
      setIsError(true);
    }

    if (response?.error) {
      setError(response.error);
      setIsError(true);
      e.target.value = "";
    } else {
      if (response?.warnings) {
        setError(response.warnings);
        setIsError(true);
      }

      const project = {
        name: e.target.files[0].name,
        type: "Excel",
        state: response,
        temperature: response.температура,
      };

      await createProject(project)
        .then(res => {
          // setError("");
          if (!response?.warnings) {
            setIsLoading(true);
          }
          setIsCreate(false);
          setProjectName("");
          e.target.value = "";
          setProjectOpenId(res.data._id);
          if (!response?.warnings) {
            window.location.href = `/app/index.html?projectId=${res.data._id}`;
          }
        })
        .catch(res => {
          const errorsMessages = {
            400: "Введите название проекта",
            401: "Неверные данные для авторизации",
          };

          setError(errorsMessages[res.response.status]);
          setIsError(true);
          InputExcel.current.value = null;
        });
    }
  };

  const handleCreateJSON = async e => {
    if (
      e.target.files[0]?.size !== 0 &&
      e.target.files[0]?.type === "application/json"
    ) {
      const reader = new FileReader();
      const data = e.target.files[0];
      reader.readAsText(data);
      reader.onload = async function () {
        const parsedData = await JSON.parse(reader.result);

        await createProject({ ...parsedData, type: "JSON" })
          .then(res => {
            setError("");
            setIsLoading(true);
            setIsCreate(false);
            setProjectName("");
            e.target.value = "";
            window.location.href = `/app/index.html?projectId=${res.data._id}`;
          })
          .catch(res => {
            const errorsMessages = {
              400: "Введите название проекта",
              401: "Неверные данные для авторизации",
            };

            setError(errorsMessages[res.response.status]);
            setIsError(true);
            InputJSON.current.value = null;
          });
      };
    } else if (e.target.files[0]?.size === 0) {
      setError("Файл не выбран или пуст");
      setIsError(true);
      InputJSON.current.value = null;
    } else {
      setError("Файл не валиден");
      setIsError(true);
      InputJSON.current.value = null;
    }
  };

  const handleCheckProjectCard = projectId => {
    navigate(`/project/${projectId}`);
  };

  // const target = null;

  // const onDragEnter = e => {
  //   e.stopPropagation();
  //   e.preventDefault();

  //   if (!isOpen) setIsOpen(true);
  //   return false;
  // };

  // const onDragLeave = e => {
  //   e.stopPropagation();
  //   e.preventDefault();

  //   setIsOpen(false);
  //   return false;
  // };

  // const onFileDrop = e => {
  //   e.stopPropagation();
  //   e.preventDefault();

  //   setIsOpen(false);
  //   return false;
  // };

  return (
    <Box sx={{ p: "0 1.5rem" }}>
      <Box
        sx={{
          width: "100vw",
          height: "35vh",
          background: "#0D141E",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "85%",
            height: "60%",
            mt: "64px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6">Создать</Typography>

          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <Box
              sx={{
                width: "250px",
                height: "150px",
                p: "1rem 0.5rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                ":hover": {
                  backgroundColor: "#162335",
                },
              }}
              onClick={userProjects ? handleOpenCreate : null}
            >
              <Box
                sx={{
                  width: "150px",
                  height: "120px",
                  backgroundColor: "#fff",
                }}
              />

              <span>Новый проект</span>
            </Box>
            <Box
              sx={{
                width: "250px",
                height: "150px",
                p: "1rem 0.5rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                ":hover": {
                  backgroundColor: "#162335",
                },
              }}
              onClick={userProjects ? () => handleUploadJSON() : null}
            >
              <input
                type="file"
                accept=".json"
                ref={InputJSON}
                style={{
                  display: "none",
                }}
                onChange={handleCreateJSON}
              />

              <Box
                sx={{
                  width: "150px",
                  height: "100px",
                  display: "flex",
                  background: "#fff",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src="/images/icons/JSON.svg" style={{ height: "80px" }} />
              </Box>

              <span>Импортировать из JSON</span>
            </Box>
            <Box
              sx={{
                width: "250px",
                height: "150px",
                p: "1rem 0.5rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                ":hover": {
                  backgroundColor: "#162335",
                },
              }}
              onClick={userProjects ? () => handleUploadExcel() : null}
            >
              <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={handleCreateExcel}
                ref={InputExcel}
                style={{
                  display: "none",
                }}
              />

              <Box
                sx={{
                  width: "150px",
                  height: "120px",
                  background: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src="/images/icons/Excel.svg" style={{ height: "80px" }} />
              </Box>

              <span>Импортировать из Excel</span>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          width: "100vw",
          height: "65vh",
          ml: {
            md: 0,
            sm: "10px",
          },
          background:
            "linear-gradient(269.39deg, rgba(25, 44, 61, 0.48) 3.53%, rgba(14, 44, 71, 0.48) 101.76%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {userProjects ? (
          <ProjectsTable
            HeadRows={itemsTableHeadRows}
            BodyRows={userProjects}
            onClickProject={handleCheckProjectCard}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            setDirection={setDirection}
            valueToOrderBy={valueToOrderBy}
            setValueToOrderBy={setValueToOrderBy}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        ) : (
          <Loader />
        )}
      </Box>

      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          zIndex: 99999999,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        >
          Drop file here
        </Typography>
      </Modal>

      <Modal
        open={isCreate}
        onClose={handleCloseCreate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          zIndex: 99999999,
        }}
      >
        <Box
          sx={{
            height: "25%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Введите название проекта
          </Typography>
          <form
            onSubmit={handleSubmit}
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <TextField
              value={projectName}
              label="Название проекта"
              variant="outlined"
              placeholder={`Введите название проекта`}
              sx={() => ({
                width: "100%",
                backgroundColor: "#141d2b",
              })}
              InputProps={{
                sx: {
                  fieldset: {
                    borderColor: "rgb(149 149 149 / 23%)",
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  color: "rgb(149 149 149)",
                },
              }}
              onChange={handleChange}
            />

            <Box
              sx={{
                height: "10%",
              }}
            >
              {Array.isArray(error) ? null : (
                <Typography
                  variant="h7"
                  sx={{
                    mb: "1rem",
                    color: "red",
                  }}
                >
                  {error}
                </Typography>
              )}
            </Box>

            <LoadingButton
              loading={isLoading}
              variant="contained"
              type="submit"
              sx={{
                width: "60%",
                backgroundColor: "#42a5f5",
              }}
              color="primary"
            >
              Создать
            </LoadingButton>
          </form>
        </Box>
      </Modal>

      <ModalWithButton
        open={isError}
        closeFunction={handleCloseIsError}
        styles={{
          flexDirection: "column",
          ...(Array.isArray(error)
            ? {
                minWidth: 860,
                maxHeight: "60%",
                overflowY: "auto",
                justifyContent: "",
              }
            : { minWidth: 400 }),
          ...(Array.isArray(error) && error.length === 1
            ? { alignItems: "center", minWidth: 400 }
            : null),
        }}
      >
        {Array.isArray(error) ? (
          error.map((err, index) => (
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              key={index}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {err?.field
                ? `Ошибка на листе "${err.sheet}", поле "${err.field}", ${err.row} строкa: -  Поле имеет пустое значение.`
                : `${err.title}`}
            </Typography>
          ))
        ) : (
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {error}
          </Typography>
        )}
      </ModalWithButton>
    </Box>
  );
};

export default MyProjectsView;
