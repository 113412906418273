import LayoutUnauthorize from "../../layouts/LayoutUnauthorize";
import ResetPasswordView from "../../views/ResetPasswordView";

const ResetPassword = () => {
  return (
    <LayoutUnauthorize title={"Сброс пароля"}>
      <ResetPasswordView />
    </LayoutUnauthorize>
  );
};

export default ResetPassword;
